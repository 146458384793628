<template>
  <div>
    <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        color="blue"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <!-- =======================================================
                                Dialog
    ============================================================-->
    <v-dialog v-model="openDialog" width="500">
      <v-card>
        <v-card-title class="headline red white--text" primary-title>
          <v-spacer />Atenção
          <v-spacer />
        </v-card-title>
        <v-card-text class="pa-5"
          >Deseja sair, todo o processo será encerrado</v-card-text
        >

        <v-divider></v-divider>

        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn text @click="openDialog = false">Não</v-btn>
          <v-spacer></v-spacer>
          <v-btn text to="/pages/agreements">Sim</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- =======================================================
                                Snackbar
    ============================================================-->
    <v-snackbar v-model="snackbar.active">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.active = false"
          >Cancelar</v-btn
        >
      </template>
    </v-snackbar>

    <!-- =======================================================
                                Main
    ============================================================-->

    <v-container id="createEditUser" tag="section">
      <v-row justify="center" class="mt-16">
        <v-col cols="6">
          <v-form>
            <v-container>
              <v-row justify="center">
                <v-col cols="12 pa-0">
                  <v-text-field
                    v-model="description"
                    label="Descrição"
                    outlined
                    required
                  ></v-text-field>
                  <v-select
                    v-model="category"
                    outlined
                    :items="categoryes"
                    :rules="[(v) => !!v || 'Escolha uma categoria']"
                    label="Categoria"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-select
                    v-model="yearInit"
                    outlined
                    :items="years"
                    label="Ano inicial"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pr-0">
                  <v-select
                    :disabled="yearsComputed.length === 0"
                    v-model="yearEnd"
                    outlined
                    :items="yearsComputed"
                    label="Ano final"
                    required
                  ></v-select>
                </v-col>
                <v-file-input
                  v-model="files"
                  accept=".csv,.xls,.xlsx,.txt,.pdf,.xml,.png,.jpg,.jpeg,.gif,.svg,.doc,.docx"
                  @change="alterFiles()"
                  label="Adicionar arquivos"
                  outlined
                ></v-file-input>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
        <v-divider vertical></v-divider>

        <v-col cols="5" class="px-0">
          <v-container>
            <v-toolbar-title class="blue white--text pa-2 text-center"
              >Lista de arquivos</v-toolbar-title
            >
            <v-card
              class="mx-auto overflow-y-auto"
              max-width="100%"
              max-height="300"
              height="300"
              tile
            >
              <v-list :nav="true" :three-line="true">
                <v-list-item-group color="primary">
                  <v-subheader v-if="filesAll.length === 0"
                    >Nenhum arquivo adicionado.</v-subheader
                  >

                  <v-list-item v-for="(file, index) in filesAll" :key="index">
                    <v-icon large class="mr-5" color="blue"
                      >mdi-file-upload-outline</v-icon
                    >
                    <v-list-item-content>
                      <v-list-item-subtitle
                        v-html="file.name"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-btn
                      class="mx-2"
                      @click="removeFile(index)"
                      icon
                      dark
                      small
                      color="red"
                    >
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11" class="mt-10">
          <v-row justify="space-between">
            <v-btn text @click="cancel()">Cancelar</v-btn>
            <v-btn color="primary" @click="create()">Salvar</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-spacer />
  </div>
</template>

<script>
import categoryService from "../../../../service/categoryService";
import agreementsService from "../../../../service/agreementsService";

export default {
  name: "CreateEditAgreements",
  data() {
    return {
      description: "",
      category: "",
      categoryes: [],
      yearInit: "",
      yearEnd: null,
      years: [],
      files: [],
      filesAll: [],
      fileRemove: [],
      overlay: false,
      openDialog: false,
      snackbar: {
        text: "",
        active: false,
      },
    };
  },
  methods: {
    removeFile(index) {
      if (this.filesAll[index] && this.filesAll[index].id_agreements_content) {
        this.fileRemove.push(this.filesAll[index].id_agreements_content);
      }
      this.filesAll.splice(index, 1);
      this.files = this.filesAll;
    },
    alterFiles() {
      this.filesAll = this.filesAll.concat(this.files);
    },
    cancel() {
      this.description || this.description || this.yearInit
        ? (this.openDialog = true)
        : "";
    },
    create() {
      if (this.validateData()) {
        const params = {
          description: this.description,
          id_category: this.category,
          year_init: this.yearInit,
          year_end: this.yearEnd,
        };

        const formData = new FormData();
        if (this.filesAll.length > 0) {
          this.filesAll.forEach((file) => {
            if (file && file.type) {
              formData.append("fileAll", file, file.name);
            }
          });
        }

        formData.append("description", this.description);
        formData.append("id_category", this.category);
        formData.append("year_init", this.yearInit);
        formData.append("year_end", this.yearEnd);

        if (this.id) {
          if (this.fileRemove.length > 0) {
            formData.append("fileRemove", JSON.stringify(this.fileRemove));
          }

          this.overlay = true;
          agreementsService
            .update(this.id, formData)
            .then((res) => {
              setTimeout(() => {
                this.overlay = false;
                this.$router.push({ path: "/pages/agreements" });
              }, 2000);
            })
            .catch((error) => {
              this.snackbar.text = "Erro ao salvar o acordo.";
              this.snackbar.active = true;
            });
        } else {
          this.overlay = true;
          agreementsService
            .create(formData)
            .then((res) => {
              setTimeout(() => {
                this.overlay = false;
                this.$router.push({ path: "/pages/agreements" });
              }, 2000);
            })
            .catch((error) => {
              this.overlay = false;
              this.snackbar.text = "Erro ao salvar o acordo.";
              this.snackbar.active = true;
            });
        }
      }
    },
    validateData() {
      if (!this.description) {
        this.snackbar.text = "A descrição não pode ser vazia.";
        this.snackbar.active = true;
        return false;
      } else if (!this.category) {
        this.snackbar.text = "A categoria não pode ser vazia.";
        this.snackbar.active = true;
        return false;
      } else if (!this.yearInit) {
        this.snackbar.text = "O ano de inicio não pode ser vazio.";
        this.snackbar.active = true;
        return false;
      } else if (this.filesAll.length === 0) {
        this.snackbar.text = "Pelo menos um arquivo deve se adicionado";
        this.snackbar.active = true;
        return false;
      }

      return true;
    },
    getProfiles() {
      userService.getAllProfile().then((res) => {
        this.profiles = res.data.map((profile) => {
          return {
            value: profile.id,
            text: profile.name === "ADMIN" ? "Administrador" : "Colaborador",
          };
        });
      });
    },
    getCategorys() {
      categoryService.get({}).then((res) => {
        this.categoryes = res.data.map((category) => {
          category.value = category.id;
          category.text = category.name;
          return category;
        });
      });
    },
  },
  computed: {
    yearsComputed() {
      return this.years.filter((year) => year.value > this.yearInit);
    },
  },
  mounted() {
    this.id = parseInt(this.$router.history.current.params.id);
    this.getCategorys();

    for (let index = 1990; index < 2040; index++) {
      this.years.push({ text: String(index), value: index });
    }

    if (this.id) {
      agreementsService.get({ all: true, id: this.id }).then((res) => {
        const data = res.data;
        this.description = data[0].description;
        this.category = data[0].id_category;
        this.yearInit = parseInt(data[0].year_init);
        this.yearEnd = data[0].year_end ? parseInt(data[0].year_end) : null;

        this.filesAll = data.map((file) => {
          return {
            path: file.path,
            name: file.name,
            id_agreements_content: file.id_agreements_content,
          };
        });
      });
    } else {
      this.yearInit = parseInt(new Date().getFullYear());
    }
  },
};
</script>

<style lang="sass" scoped></style>
